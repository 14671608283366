.gocd-card-img {
  background: linear-gradient(#effbfe, #787e7f);
}

.gocd-svg {
  transition: all 0.2s ease-in-out;
}

.gocd-svg:hover {
  transform: scale(1.1);
}

.rvh-card {
  margin-top: 7.5em !important;
}

.read-more {
  text-decoration: none;
  color: #1e44ff !important;
}
