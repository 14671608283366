.tags {
  text-decoration: none !important;
  color: #aaaaaa;
}

.tags:hover {
  color: #737373 !important;
}

.banner-col {
  line-height: 72px !important;
}

.more-row {
  margin-top: 20px !important;
}
