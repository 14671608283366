.Tce-card {
  margin-top: 80px;
}

.tce-card-img {
  background: linear-gradient(#efefef, #787e7f);
}

.tce-svg {
  transition: all 0.2s ease-in-out;
}

.tce-svg:hover {
  transform: scale(1.1);
}
