.footer {
  position: absolute;
  left: 0;
  bottom: 10;
  right: 0;
  margin-top: 80px;
}

.linkedIn {
  transition: ease-in-out 0.2s;
}

.linkedIn:hover {
  transform: scale(1.1);
}

.email {
  text-decoration: none !important;
  color: black;
}
