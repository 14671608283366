.role-desk {
  font-size: 14px;
  letter-spacing: 7px;
  line-height: 30px;
  color: #1e44ff;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 80%;
}

.proj-title {
  font-size: 60px;
  line-height: 80px;
}

h4 {
  font-size: 16px !important;
  line-height: 32px;
  font-weight: 700 !important;
}

p {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 30px;
}
