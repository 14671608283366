.about-me.row {
  margin-top: 40px;
}

.tech-stack.row {
  margin-top: 80px;
}

.diciplines.row {
  margin-top: 80px;
}

.lets-connect.row {
  margin-top: 80px;
}
