@font-face {
  font-family: "Orkney-Bold";
  src: url("./assets/fonts/orkney-bold.otf") format("opentype");
}

.orkney-bold {
  font-family: "Orkney-Bold", sans-serif;
}

@font-face {
  font-family: "Orkney-Light";
  src: url("./assets/fonts/orkney-light.otf") format("opentype");
}
.orkney-light {
  font-family: "Orkney-Light", sans-serif;
}

@font-face {
  font-family: "Orkney-Medium";
  src: url("./assets/fonts/orkney-medium.otf") format("opentype");
}

.orkney-medium {
  font-family: "Orkney-Medium", sans-serif;
}

@font-face {
  font-family: "Orkney-Regular";
  src: url("./assets/fonts/orkney-regular.otf") format("opentype");
}

.orkney-regular {
  font-family: "Orkney-Regular", sans-serif;
}

body {
  background-color: #ebebeb !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
