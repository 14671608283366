.navigation {
  font-family: "orkney-bold", sans-serif !important;
}

.nav-hover {
  text-transform: uppercase !important;
  font-weight: 700 !important;
  letter-spacing: 2px;
  margin: 0 0.5em 0 0.5em;
  display: inline-block;
  position: relative;
}
/* 
Maybe underline while active?
.nav-link.active {
  text-decoration: underline;
} */

.nav-hover:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  border-radius: 300px;
  height: 0.1em;
  bottom: 0;
  left: 0;
  background: #1e44ff;
  transform-origin: center;
  transition: transform 0.25s ease-out;
}

.nav-hover:hover::after {
  transform: scaleX(1);
  transform-origin: right left;
}

.nav-hover:hover {
  text-decoration: none !important;
}
