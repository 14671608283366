.projects-header {
  position: fixed;
  transform: rotate(90deg);
  right: 0;
  color: #212529;
  z-index: 9999;
  margin-top: 5rem;
}

.welcome-banner {
  margin-top: 40px;
}
